import { render, staticRenderFns } from "./LeadUnico.vue?vue&type=template&id=b8fb0bb4&scoped=true"
import script from "./LeadUnico.vue?vue&type=script&lang=js"
export * from "./LeadUnico.vue?vue&type=script&lang=js"
import style0 from "./LeadUnico.vue?vue&type=style&index=0&id=b8fb0bb4&prod&lang=scss&scoped=true"
import style1 from "./LeadUnico.vue?vue&type=style&index=1&id=b8fb0bb4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8fb0bb4",
  null
  
)

export default component.exports